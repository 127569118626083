import React, {Component} from 'react';
import Advice from "../Advice/Advice";
import logo from "../../assets/images/logo.svg";
import {sendGet} from '@app-services/ajax';
import {footerUrl} from '@app-constants/api';

class Footer extends Component {

  state = {
    footerInfo: ''
  };

  componentDidMount() {
    this.loadFooterInfo();
  }

  loadFooterInfo() {
    (async () => {
      let response;
      const url = footerUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        footerInfo: response.data.text || ''
      });
    })();
  }

  render() {

    return (
      <section className="section_footer">
        <div className="footer container">
          <Advice headerText={this.props.headerText}/>
          <div className="footer_info_wrap">
            <div className="footer_info"
                 dangerouslySetInnerHTML={{__html: this.state.footerInfo|| ''}}>
            </div>
            <div className="logo_wrap">
              <div className="logo">
                <img src={logo} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
};

export default Footer;
