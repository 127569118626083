function isLeapYear(year) {
    return new Date(year, 1, 29).getDate() === 29;
}

function normalizeDay(day, min, max) {
  (day > max) && (day = max);
  (day < min) && (day = min);
  return day;
}

function normalizeMonth(month, min, max) {
  (month > max) && (month = max);
  (month < min) && (month = min);
  return month;
}

export function beforeMaskedValueChange(newState, oldState, userInput) {
	const selection = newState.selection;
	const maxMonthNumber = 12;
	const minMonthNumber = 0;
	const maxYearNumber = (new Date()).getFullYear();
	const maxDayNumber = 31;
	const minDayNumber = 1;
	const monthsList = [];
	monthsList[1] = {days: 31};
	monthsList[2] = {days: 28};
	monthsList[3] = {days: 31};
	monthsList[4] = {days: 30};
	monthsList[5] = {days: 31};
	monthsList[6] = {days: 30};
	monthsList[7] = {days: 31};
	monthsList[8] = {days: 31};
	monthsList[9] = {days: 30};
	monthsList[10] = {days: 31};
	monthsList[11] = {days: 30};
	monthsList[12] = {days: 31};

  let { value } = newState;
  let result = [];
	const parsedValue = (value.split('.') || []);
	let valueYearOld = parsedValue.length === 3 ? parsedValue[2] : '';
	let valueMonthOld = parsedValue.length >= 2 ? parsedValue[1] : '';
	let valueDaysOld = parsedValue.length >= 1 ? parsedValue[0] : '';

	let valueYearLength = valueYearOld.length;
	let valueMonthLength = valueMonthOld.length;
	let valueDaysLength = valueDaysOld.length;

	let valueYear = parseInt(valueYearOld, 10);
  let valueMonth = parseInt(valueMonthOld, 10);
  let valueDays = parseInt(valueDaysOld, 10);

  switch(true) {
  	case ((valueYearLength === 4) && !isNaN(valueMonth)): {
  		(valueYear > maxYearNumber) && (valueYear = maxYearNumber);
  		(typeof monthsList[valueMonth] === 'undefined') && (valueMonth = maxMonthNumber);
      valueMonth = normalizeMonth(valueMonth, minMonthNumber, maxMonthNumber);
      valueDays = normalizeDay(valueDays, minDayNumber, maxDayNumber);

			if ((valueMonth === 2 && isLeapYear(valueYear)) && (valueDays > 29)) {
				valueDays = 29;
			}
			else {
				(valueDays > monthsList[valueMonth].days) && (valueDays = monthsList[valueMonth].days);
			}
  		break;
  	}

  	case !isNaN(valueMonth) && (valueMonth !== 0 || valueMonthLength === 2): {
      valueMonth = normalizeMonth(valueMonth, minMonthNumber, maxMonthNumber);
      valueDays = normalizeDay(valueDays, minDayNumber, maxDayNumber);
      (typeof monthsList[valueMonth] === 'undefined') && (valueMonth = maxMonthNumber);

			if (!((valueMonth === 2 && isLeapYear(valueYear)) && (valueDays > 29))) {
				(valueDays > monthsList[valueMonth].days) && (valueDays = monthsList[valueMonth].days);
			}
  		break;
  	}

    default: {

    }
  }

	if (valueDaysLength) {
		result.push(
		  (valueMonth && valueDays < 10) || (valueDaysLength === 2 && valueDays < 10)
        ? '0' + valueDays
        : valueDays
    );
	}

	if (valueMonthLength) {
		result.push(
		  (valueYear && valueMonth < 10) || (valueMonthLength === 2 && valueMonth < 10)
        ? '0' + valueMonth
        : valueMonth
    );
	}

	if (valueYearLength) {
		result.push(valueYearLength < 4 ? valueYearOld : valueYear);
	}

	return {
    value: result.join('.'),
    selection
  };
}
