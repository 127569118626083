import React, {Component} from 'react';
import AdvantageItem from '../../components/AdvantageItem/AdvantageItem';
import {advantagesUrl} from '@app-constants/api';
import {sendGet} from '@app-services/ajax';
// eslint-disable-next-line
var yall = require('script-loader!yall-js/dist/yall.min.js');

class Advantages extends Component {
  state = {
    advantagesList: []
  };

  componentDidMount() {
    this.setState({advantagesList: this.renderListAdvantages()});
  }

  componentDidUpdate() {
    if (Array.isArray(this.state.advantagesList) && this.state.advantagesList.length) {
      window.yall();
    }
  }

  renderListAdvantages() {
    (async () => {
      let response;
      const url = advantagesUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        advantagesList: response.data
      });
    })();
  }

  render() {
    return (
      <section className="section_advantages container">
        {this.renderList()}
      </section>
    )
  }

  renderList() {
    const advantageItem = (this.state.advantagesList || []).map(({image, text}, index) => {
      return (
        <AdvantageItem key={index} icon={image} text={text} />
      );
    });

    if (advantageItem.length > 0) {
      return (
          <div className="advantages_wrap">
            {advantageItem}
          </div>
      );
    }
    return <div/>;
  }

}

export default Advantages;
