import React from 'react';
import classNames from 'classnames/bind';
import Button from '../../components/FormElements/Button'
import imageSlon from '../../assets/images/thanks_img.png'

const Modal = props => {
  const cls = classNames( 'modal_overflow', {'show': props.show});

  function hideModal() {
    if (typeof props.hideModal === 'function') {
      props.hideModal();
      document.querySelector('body').classList.remove('overflow');
    }
  }

  return (
    <div className={cls}>
      <div className="modal_container">
        <div className="modal_close" onClick={hideModal}/>
        <div className="modal_title">
          Теперь мы знаем друг друга лучше, <br />
          спасибо за доверие!
        </div>
        <div className="modal_subtitle">Мы свяжемся с тобой в ближайшее время!</div>
        <div className="modal_image">
          <img src={imageSlon} alt=""/>
        </div>
        <div className="modal_btn_wrap">
          <Button text='Закрыть' type='button' onClick={hideModal} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
