import React, {Component} from "react";
import Formsy from 'formsy-react';
import InputPhoneMask from "../../components/FormElements/InputPhoneMask";
import {consultationStoreUrl, creditUrl} from "../../constants/api";
import {sendPost} from '@app-services/ajax';
import {sendGet} from '@app-services/ajax';
import Button from "../../components/FormElements/Button";
import Modal from "../../components/Modal/Modal";
import Checkbox from "../../components/FormElements/Checkbox";
import scrollTo from "scroll-to-element";
import ConsultImgWebp from '../../assets/images/dude.webp'
import ConsultImg from '../../assets/images/dude.png'

class Credit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      isCheked: true,
      formSended: false,
      creditText: {
        header1 : '',
        header2: '',
        description: '',
        text: ''
      }
    };
    this.fromRef = React.createRef();
    this.clearFormTimer = null;
  }

  componentDidMount() {
    this.renderCreditText();
  }

  renderCreditText() {
    (async () => {
      let response;
      const urlText = creditUrl;

      try {
        response = await sendGet(urlText);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        creditText: response.data.length > 0 ? response.data[0] : {}
      });
    })();
  }

  submit = (model) => {
    (async () => {
      const url = consultationStoreUrl;
      const data = {
        phone: model.phone,
        fio: 'credit_form_consult',
        form_type: 'consult_form'
      };
      if(!this.state.formSended) {
        this.setState({ formSended: true });
        try {
          await sendPost(url, data);
        } catch (err) {
          throw new Error(err);
        }
        // if(typeof window.yaCounter56850232 !== 'undefined') {
        //   window.yaCounter56850232.reachGoal("zvcons");
        // }
        // if(window.gtag !== 'undefined') {
        //   window.gtag('event', 'conversion', {'send_to': 'AW-668997757/sjkSCPCylsIBEP2wgL8C'});
        // }
        // if(typeof window.gtag !== 'undefined') {
        //   window.gtag('config', 'UA-155061629-1', {'page_path': '/zvcons'});
        // }
        document.querySelector('body').classList.add('overflow');
        this.setState({
          modalShow: true,
          formSended: false
        });
      }
    })();

  };

  handleIsChecked = (value) => {
    this.setState({isCheked: value})
  };

  hideModal = () => {
    this.fromRef.current.reset();
    this.setState({
      modalShow: false
    });
    scrollTo('.section_feedback', {
      offset: 0,
      ease: 'inOutQuad',
      duration: 500
    });
  };

  handleClearValidate = (model) => {
    if (!model.phone) {
      this.clearFormTimer = setTimeout(() => {
        this.fromRef.current.reset();
      }, 1000);
    }
  };

  handleStopClearTimer = (model) => {
    if (model.phone) {
      clearTimeout(this.clearFormTimer);
    }
  };

  render() {
    return (
      <section className="section_credit_consult">
        <div className="credit_consult_wrap container">
          <div className="credit_consult_left">
          <h1>{typeof this.state.creditText.header1 !== 'undefined' ? this.state.creditText.header1 : ''}</h1>
          <div className="credit_consult_text"
               dangerouslySetInnerHTML={{
                 __html: typeof this.state.creditText.text !== 'undefined' ? this.state.creditText.text : ''
               }}
          >
          </div>
            <div className="credit_consult_subtitle">
              {typeof this.state.creditText.header2 !== 'undefined' ? this.state.creditText.header2 : ''}
            </div>
            <Formsy className="credit_consult_form" onValidSubmit={this.submit} onChange={this.handleStopClearTimer}
                    onInvalidSubmit={this.handleClearValidate}
                    ref={this.fromRef}>
              <div className="credit_consult_form_content">
                <InputPhoneMask label='Телефон' name='phone' validations={{
                  matchRegexp: /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/,
                  isLength: 18
                }} required/>
                <div className="credit_consult_bank_info">
                  {typeof this.state.creditText.description !== 'undefined' ? this.state.creditText.description : ''}
                </div>
                <Button text='Узнать вероятность' type='submit' disabled={!this.state.isCheked}/>
                <Checkbox isChecked={this.handleIsChecked}/>
              </div>
              <Modal show={this.state.modalShow} hideModal={this.hideModal}/>
            </Formsy>
          </div>
          <div className="credit_consult_right">
            <picture>
              <source srcSet={ConsultImgWebp} type="image/webp"/>
              <img src={ConsultImg} alt=""/>
            </picture>
          </div>
        </div>
      </section>
    )
  }
}

export default Credit;
