import React, {Component} from 'react';
import SelectCustom from "../FormElements/SelectCustom";
import Button from "../FormElements/Button";
import classNames from 'classnames/bind';

class FormStepOne extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMotivate: false,
      showBtnLoad: false,
      errorMessage: '* Сперва выберите марку',
      showErrorMessage: false
    };
  }

  handleSubmit = (data) => {
    this.props.onSubmit({
      brandId: this.props.selectedBrand,
      modelId: this.props.selectedModel,
      year: this.props.activeYear,
    });
    this.setState({showMotivate: true, showBtnLoad: true}, () => {
      setTimeout(() => {
        this.setState({showMotivate: false});
      }, 300);
    });

    if (this.props.actionsMessage === '') {
      if(typeof window.yaCounter56850232 !== 'undefined') {
        window.yaCounter56850232.reachGoal("budavt");
      }
      if(typeof window.gtag !== 'undefined') {
        window.gtag('config', 'UA-155061629-1', {'page_path': '/budavt'});
      }
      this.props.changeStep(1);
    } else {
      setTimeout(() => {
        if(typeof window.yaCounter56850232 !== 'undefined') {
          window.yaCounter56850232.reachGoal("budavt");
        }
        if(typeof window.gtag !== 'undefined') {
          window.gtag('config', 'UA-155061629-1', {'page_path': '/budavt'});
        }
        this.props.changeStep(1);
      }, 2500)
    }
  };

  handleShowErrorMessage = () => {
    if (this.props.selectedBrand === null) {
      this.setState({showErrorMessage: true})
    } else {
      this.setState({showErrorMessage: false})
    }
  };

  changeBrand = (value) => {
    if (typeof this.props.changeBrand === 'function') {
      this.props.changeBrand(value);
    }
    setTimeout(() => {
      this.handleShowErrorMessage();
      // this.showErrorMessage();
    }, 1);
  };

  // showErrorMessage = () => {
  //   if(this.props.carModels.length < 0) {
  //     this.setState({errorMessage: 'Нет моделей', showErrorMessage: true})
  //   }
  //   console.log(this.props.carModels.length);
  // };

  render() {
    const motivateCls = classNames('motivation_info', {'active': this.state.showMotivate});
    const showCarInfo = classNames('random_car_info', {'active': this.props.selectedBrand !== null});
    const clsBtnStep = classNames('icon', {'load': this.state.showBtnLoad && this.props.actionsMessage !== ''});
    const clsSelect = classNames({'show_error': this.state.showErrorMessage});
    return (
      <div className='form_content_step'>
        <div className="form_content_select_wrap">
          <SelectCustom
            label='Марка'
            handleChange={this.changeBrand}
            items={this.props.carMarks}
            value={this.props.getCarMarkById(this.props.selectedBrand) || ''}
          />
          <SelectCustom
            handleChange={this.props.changeModel}
            items={this.props.carModels}
            value={this.props.getCarModelById(this.props.selectedModel) || ''}
            showErrorMessage={this.state.errorMessage}
            cls={clsSelect}
            handleShowErrorMessage={this.handleShowErrorMessage}
            label='Модель'/>
        </div>
        <div className="form_content_years">
          <div className="form_content_years_title">Год выпуска авто</div>
          <div className="form_content_years_items_wrap">
            <div className="form_content_years_items">
              {this.props.yearsList()}
            </div>
          </div>
        </div>
        <div className="form_content_step_bottom form_content_step_one_bottom">
          <div className="form_content_step_bottom_left">
            <span className={showCarInfo}>{this.props.selectedBrandName} {this.props.selectedModelName}
              <br/>{this.props.activeYear} года выпуска</span>
          </div>
          <div className="form_content_step_bottom_right">
            <div className="motivation_wrap">
              {this.props.actionsMessage ? <div className={motivateCls}>{this.props.actionsMessage}</div> : null}
              <Button text='Далее' cls={clsBtnStep} type='button'
                      disabled={this.props.selectedModel === null}
                      onClick={this.handleSubmit}/>
            </div>
          </div>
          <div className="mobile_only">
            <div className="mobile_steps_nav center">
              <div className="mobile_steps_nav_number">
                <span className='green'>Шаг&nbsp;</span>
                <span className="green">1&nbsp;</span>
                <span>из 3</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

};

export default FormStepOne;
