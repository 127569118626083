import React, {Component} from 'react';
import Formsy from 'formsy-react';
import InputPhoneMask from "../../components/FormElements/InputPhoneMask";
import InputString from "../../components/FormElements/InputString";
import {consultationStoreUrl} from "../../constants/api";
import {sendPost} from '@app-services/ajax';
import Button from "../../components/FormElements/Button";
import Modal from "../../components/Modal/Modal";
import Checkbox from "../../components/FormElements/Checkbox";
import scrollTo from "scroll-to-element";

class Advice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      isCheked: true,
      formSended: false
    };
    this.fromRef = React.createRef();
    this.clearFormTimer = null;
  }

  submit = (model) => {
    (async () => {
      const url = consultationStoreUrl;
      const data = {
        phone: model.phone,
        fio: model.fio,
        form_type: 'consult_form'
      };
      if(!this.state.formSended) {
        this.setState({ formSended: true });
        try {
          await sendPost(url, data);
        } catch (err) {
          throw new Error(err);
        }
        // if(typeof window.yaCounter56850232 !== 'undefined') {
        //   window.yaCounter56850232.reachGoal("zvcons");
        // }
        // if(window.gtag !== 'undefined') {
        //   window.gtag('event', 'conversion', {'send_to': 'AW-668997757/sjkSCPCylsIBEP2wgL8C'});
        // }
        // if(typeof window.gtag !== 'undefined') {
        //   window.gtag('config', 'UA-155061629-1', {'page_path': '/zvcons'});
        // }
        document.querySelector('body').classList.add('overflow');
        this.setState({
          modalShow: true,
          formSended: false
        });
      }
    })();

  };

  handleIsChecked = (value) => {
    this.setState({isCheked: value})
  };

  hideModal = () => {
    this.fromRef.current.reset();
    this.setState({
      modalShow: false
    });
    scrollTo('.section_feedback', {
      offset: 0,
      ease: 'inOutQuad',
      duration: 500
    });
  };

  handleClearValidate = (model) => {
    if (!model.fio && !model.phone) {
      this.clearFormTimer = setTimeout(() => {
        this.fromRef.current.reset();
      }, 1000);
    }
  };

  handleStopClearTimer = (model) => {
    if (model.fio || model.phone) {
      clearTimeout(this.clearFormTimer);
    }
  };

  render() {
    return (
      <section className="section_advice">
        <h1>{this.props.headerText.advice_header}</h1>
        <Formsy className="advice_form" onValidSubmit={this.submit} onChange={this.handleStopClearTimer}
                onInvalidSubmit={this.handleClearValidate}
                ref={this.fromRef}>
          <div className="advice_form_content">
            <InputString label='Фамилия Имя' name='fio'
                         formatter={/[^a-zA-Zа-яА-ЯёЁ]{2,}\s+[^a-zA-Zа-яА-ЯёЁ]{2,}\s?/g}
                         validations={{
                                    matchRegexp: /^[а-яА-ЯёЁ]{2,}\s+[а-яА-ЯёЁ]{1,}[а-яА-ЯёЁ\s]+$/,
                                    minLength: 2
                          }}
                         validationErrors={{
                           matchRegexp: 'Заполни Фамилию и Имя'
                         }}
                         required/>
            <InputPhoneMask label='Телефон' name='phone' validations={{
              matchRegexp: /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/,
              isLength: 18
            }} required/>
            <Button text='Отправить' cls='icon' type='submit' disabled={!this.state.isCheked}/>
            <Checkbox isChecked={this.handleIsChecked}/>
          </div>
          <Modal show={this.state.modalShow} hideModal={this.hideModal}/>
        </Formsy>
      </section>
    )
  }
}

export default Advice;
