import React, {Component} from 'react';
import InputDate from "../../FormElements/InputDate";
import SelectCustom from "../../FormElements/SelectCustom";
import classNames from 'classnames/bind';

class FormStepThreeHead extends Component {

  render() {
    const clsHead = classNames('form_content_step_three_wrap mb-50', {'hide_mobile': this.props.mobileCls});
    return (
      <React.Fragment>
        <div className={clsHead}>
          <InputDate handlerOnChange={this.props.handleChangeBirthDay}
                     value={this.props.birthDay} validBirthDay={this.props.validBirthDay} label='Дата рождения' name='date_birth'/>
          <SelectCustom
            handleChange={this.props.handleChangeRegion}
            label='Регион прописки по паспорту РФ'
            items={this.props.regions}
            value={this.props.region || ''}
          />
        </div>
        {!this.props.hideBottom && this.renderBottom()}
      </React.Fragment>
    )
  }

  handleStepBack = () => {
    this.props.changeStepChild(1);
  };

  handleStepBackMobile = () => {
    this.props.changeStepChild(1);
    setTimeout(() => {
      this.props.mobileShowSecondContent();
    }, 10);
  };

  renderBottom() {
    const mobileCls = classNames('mobile_only', {'hide_mobile': this.props.mobileCls});
    return (
      <div className="form_content_step_bottom">
        <div className="form_content_step_bottom_left hide_mobile">
          <div className="btn_back_step" onClick={this.handleStepBack}>
            <span>К предыдущему шагу</span>
          </div>
        </div>
        <div className={mobileCls}>
          <div className="mobile_steps_nav">
            <div className="btn_back_step" onClick={this.handleStepBackMobile}>
              <span>К предыдущему шагу</span>
            </div>
            <div className="mobile_steps_nav_number">
              <span className='green'>Шаг&nbsp;</span>
              <span className="green">3&nbsp;</span>
              <span>из 3</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default FormStepThreeHead;
