import React, {Component} from 'react';

class AdvantageItem extends Component  {

  render() {
    return (
        <div className="advantage_item">
          <div className="advantage_logo_wrap">
            <div className='advantage_logo'>
              <img className="lazy" data-src={this.props.icon} alt=""/>
            </div>
          </div>
          <div className="advantage_text" dangerouslySetInnerHTML={{__html: this.props.text || ''}}></div>
        </div>
    );
  }
};

export default AdvantageItem;
