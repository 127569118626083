import React, {Component} from 'react';
import FormStepThreeHead from "./FormStepThreeComponents/FormStepThreeHead";
import FormStepThreeContacts from "./FormStepThreeComponents/FormStepThreeContacts";
import Loader from "./FormStepThreeComponents/FormStepThreeLoader";
import {sendGet} from '@app-services/ajax';
import {sentencesUrl} from '@app-constants/api';
import scrollTo from "scroll-to-element";

class FormStepThree extends Component {
  constructor(props) {
    const formData = props.formData;
    super(props);
    this.state = {
      showSecondContent: true,
      showLoader: false,
      sentencesList: [],
      formData: {
        birthDay: formData.birthDay || '',
        region: formData.region || null,
        fio: formData.fio || '',
        phone: formData.phone || '',
        offerId: formData.offerId || null
      },
      hintClass: false,
      mobileCls: false,
      validBirthDay: true,
      validRegion: true
    };

    this.changedStapFlag = false;
  }

  componentDidMount() {
    this.loadListSentences();
    this.validateBirthDay();
    this.validateRegion();
    if (window.matchMedia("(max-width: 759px)").matches) {
      return false
    } else {
      if (this.validateBirthDay() && this.validateRegion()) {
        this.setState({showSecondContent: true});
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.matchMedia("(max-width: 759px)").matches) {
      if (this.changedStapFlag) {
        this.changedStapFlag = false;
        scrollTo('.header_wrap', {
          offset: 50,
          ease: 'inOutQuad',
          duration: 300
        });
      }
    }
  }

  handleValidateHead = () => {
    if (this.validateBirthDay() && this.validateRegion()) {
      this.setState({
        mobileCls: true
      }, () => {
        /*setTimeout(() => {
          this.props.changeMobileClass();
        }, 10);*/
        /*setTimeout(() => {
          this.props.changeClsAnimate();
        }, 20);*/
        /*setTimeout(() => {
          this.setState({showLoader: true});
        }, 300);*/
        this.handleFinishLoader();
      });


    }
  };

  handlerChangeLoan = (id) => {
    this.setState({formData: {...this.state.formData, offerId: id}, hintClass: true});
  };

  handleChangeRegion = (selectedItem) => {
    this.setState({formData: {...this.state.formData, region: selectedItem}}, this.handleAfterChange);
  };

  handleChangeBirthDay = (birthDay) => {
    this.setState({formData: {...this.state.formData, birthDay: birthDay}}, this.handleAfterChange);
  };

  loadListSentences() {
    (async () => {
      let response;
      const url = sentencesUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        sentencesList: response.data
      });
    })();
  }

  handleAfterChange = () => {
    this.validateBirthDay();
    this.validateRegion();
    if (window.matchMedia("(max-width: 759px)").matches) {
      return false;
    }
    this.handleValidateHead();
  };

  validateBirthDay = () => {
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    maxDate.setFullYear(maxDate.getFullYear() - 65);
    const enteredDate = this.state.formData.birthDay.split('.');
    if (enteredDate.length !== 3 || enteredDate[2].length !== 4) {
      this.setState({validBirthDay: false});
      return false;
    }
    const enterDateFormatter = new Date(parseInt(enteredDate[2], 10), parseInt(enteredDate[1]) - 1, parseInt(enteredDate[0]));
    const isValidMinDate = !((minDate.getTime() - enterDateFormatter.getTime()) < 0);
    const isValidMaxDate = !((maxDate.getTime() - enterDateFormatter.getTime()) < 0);

    if (
      (isValidMinDate && isValidMaxDate)
      || (!isValidMinDate)
    ) {
      this.setState({validBirthDay: false});
      return false;
    }

    this.setState({validBirthDay: true});
    return true;
  };

  validateRegion = () => {
    if (
      (this.state.formData.region === null)
      || (typeof this.state.formData.region !== 'object')
    ) {
      this.setState({validRegion: false});
      return false;
    }
    this.setState({validRegion: true});
    return true;
  };

  handleSubmitHead = (data) => {
    this.setState({formData: {...this.state.formData, ...data}}, () => {
      this.props.changeMobileClass();
    });
  };

  handleSubmitContacts = (data) => {
    this.setState({formData: {...this.state.formData, ...data}}, () => {
      this.props.onSubmit({...this.state.formData, stepThreeShowSecondContent: this.state.showSecondContent});
    });
  };

  handleFinishLoader = () => {
    this.setState({showLoader: false, showSecondContent: true});
    if (this.props.countOffers === 6) {
      this.props.changeClsAnimateFullHeight();
    }
    if (this.props.countOffers === 3) {
      this.props.changeClsAnimateFullSecondHeight();
    }
  };

  handleChangeStepChild = (value) => {
    this.props.onSubmitStep({
      birthDay: this.state.formData.birthDay,
      region: this.state.formData.region,
    });
    this.props.changeStep(value);
  };

  handleChangeStep = () => {
    this.props.onSubmitStep({
      birthDay: this.state.formData.birthDay,
      region: this.state.formData.region,
      fio: this.state.formData.fio,
      phone: this.state.formData.phone,
      offerId: this.state.formData.offerId,
      stepThreeShowSecondContent: this.state.showSecondContent
    });
    this.props.clearClsAnimate();
    this.props.changeClsStatic();
    this.props.changeStep(1);
  };

  handleChangeLastStepMobile = () => {
    this.props.onSubmitStep({
      fio: this.state.formData.fio,
      phone: this.state.formData.phone,
      offerId: this.state.formData.offerId,

    });
    this.changedStapFlag = true;
    this.setState({mobileCls: false, showSecondContent: false});
    this.props.changeMobileClass();
    this.props.handleChangeClsAnimate();
    this.props.changeClsAnimateFullHeight();
    this.props.changeClsAnimateFullSecondHeight();
  };

  handleChangeFieldsContacts = (model) => {
    this.setState({formData: {...this.state.formData, ...model}});
  };

  handleMobileShowSecondContent = () => {
    this.setState({showSecondContent: false});
  };

  render() {
    return (
      <div className='form_content_step'>
        <FormStepThreeHead onSubmit={this.handleSubmitHead}
                           hideBottom={this.state.showSecondContent || this.state.showLoader}
                           mobileShowSecondContent={this.handleMobileShowSecondContent}
                           changeStepChild={this.handleChangeStepChild}
                           birthDay={this.state.formData.birthDay}
                           region={this.state.formData.region}
                           validateBirthDay={this.validateBirthDay}
                           validateRegion={this.validateRegion}
                           regions={this.props.regions}
                           handleChangeBirthDay={this.handleChangeBirthDay}
                           handleChangeRegion={this.handleChangeRegion}
                           mobileCls={this.state.mobileCls}
                           validBirthDay={this.state.validBirthDay}
                           validRegion={this.state.validRegion}
                           handleValidateHead={this.handleValidateHead}
        />
        {this.state.showLoader === true && !this.state.showSecondContent &&
        <Loader onFinish={this.handleFinishLoader}/>}
        {this.renderSecondContent()}
      </div>
    )
  }

  renderSecondContent() {
    if (!this.state.showSecondContent) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="form_content_step_bottom">
          <FormStepThreeContacts
            handleChangeFields={this.handleChangeFieldsContacts}
            handleOnSubmit={this.handleSubmitContacts}
            fieldFioValue={this.state.formData.fio}
            fieldPhoneValue={this.state.formData.phone}
            hintClass={this.state.hintClass}
            validBirthDay={this.state.validBirthDay}
          />
          <div className="form_content_step_bottom_left">
            <div className="btn_back_step hide_mobile" onClick={this.handleChangeStep}>
              <span>К предыдущему шагу</span>
            </div>
            <div className="btn_back_step mobile_only"
                 style={{marginTop: '10px'}}
                 onClick={this.handleChangeLastStepMobile}>
              <span>К предыдущему шагу</span>
            </div>
          </div>
          <div className="form_content_step_bottom_right">
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormStepThree;
