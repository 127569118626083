import React, {Component} from 'react';
import HeaderInfoItem from './HeaderInfoItem';
import Icon1 from '../../assets/images/head1.svg';
import Icon2 from '../../assets/images/head2.svg';
import Icon3 from '../../assets/images/head3.svg';

class HeaderInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      infoItem: [
        {icon: Icon1, number: '1', text: props.slonStepsInfo.sloan_step_one, item_number: 'item_1'},
        {icon: Icon2, number: '2', text: props.slonStepsInfo.sloan_step_two, item_number: 'item_2'},
        {icon: Icon3, number: '3', text: props.slonStepsInfo.sloan_step_three, item_number: 'item_3'}
      ]
    }
  };

  render() {
    const
      cls = [
        'header_info_wrap',
        'container',
        this.props.cls
      ];

    const items = this.state.infoItem.map(({icon, iconwebp, number, text, item_number}, index) => {
      return (
        <HeaderInfoItem
          key={index}
          icon={icon}
          iconwebp={iconwebp}
          number={number}
          text={text}
          item_number={item_number}
        />
      )
    });
    return (
      <div className={cls.join(' ')}>
        <div className="header_info">
          {items}
        </div>
      </div>
    );
  }
};

export default HeaderInfo;
