export const baseUrl = '';
export const ERROR_DEFAULT = 'Ошибка. Попробуйте позднее.';

export const feedbacksUrl = '/feedbacks';
export const brandsUrl = '/brands';
export const modelsUrl = '/models';
export const creditFormStoreUrl = '/credit-form/store';
export const consultationStoreUrl = '/consultation/store';
export const questionsUrl = '/questions';
export const regionsUrl = '/regions';
export const banksUrl = '/banks';
export const actionUrl = '/actions';
export const sentencesUrl = '/sentences';
export const regionIpUrl = '/regionip';
export const actionsStepTwoUlr = '/second_steps';
export const footerUrl = '/footer';
export const stepsUrl = '/steps';
export const headersUrl = '/headers';
export const phonesUrl = '/phone';
export const advantagesUrl = '/advantages';
export const creditUrl = '/find_credit';
